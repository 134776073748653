
.Main {
    .rugby-header {
        background-color: #3C2D84;
    }
    h1 {
        text-align: center;
        margin-bottom: 0.5rem;
        margin-top: 0rem;
        color: white;
        font-family: 'promesh';
    }
    h2 {
        font-weight: normal;
        font-size: 1.6rem;
        margin: 0 0 1rem 2rem;
    }
    .logo-container {
        width: 100%;
        display: flex;
        justify-content: center;

        #rwc-logo {
            height: 100px;
            margin-bottom: 10px;
        }
    }
}