.Ratings {
  cursor: pointer;
 
  .rating-container {
    border: 1px solid #3C2D84;
    // border-radius: 50%;
    border-radius: .28571429rem;
    width: 8rem;
    height: 3rem;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    color:  #3C2D84;
    background-color: white;
    flex-wrap: wrap;
    position: relative;
    // cursor: pointer;
    margin-bottom: 1rem;
    margin: 0.5rem auto;
    

  //   &:hover {
  //     background-color: #3C2D84;
  //     color: white;
  //     cursor: pointer;

  // }


   span {
     margin-top: 0.5rem;
    flex: 1;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.6rem;

   }
    select {
       z-index: 5;
        opacity: 0;
        position: absolute;
        width: 8rem;
        height: 3rem;
        cursor: pointer;
        left: -17.5rem;
        top: -2.5rem;
        width: 44.5rem;
        height: 10rem;
        // border: solid blue 2px;
        // background-color: blue;

    }
    

   
body {
  background: #39D;
}

.circular-menu {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  position: relative;
}
.numberContainer {
    color: black;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.circle {
  width: 150px;
  height: 150px;
  opacity: 0;
  
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);

  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.open.circle {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  border: 1px solid black;
  border-radius: 50%;
//   background-color: white;
  background-color: #F0F0F0;
}

.circle span {
  text-decoration: none;
//   color: white;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  text-align: center;

}

.circle span:hover {
  color: #eef;
}

.menu-button {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  text-decoration: none;
  text-align: center;
  color: black;
  border-radius: 50%;
  display: block;
  height: 80px;
  width: 80px;
  line-height: 40px;
  padding: 10px;
//   background: #dde;
  background-color: white;
  border: 1px solid black;
  z-index: 50;
}

.menu-button:hover {
  background-color: #eef;
}


   
}


}
