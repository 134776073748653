.UpcomingGames {
  background-color: #f0f0f0;
  .upcoming-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    .personalize-container {
      display: flex;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      .info-icon {
        margin-left: 0.5rem;
        font-size: 1.2rem;
        height: 1.6rem;
      }
    }
  }
  .UpcomingGames-container {
    position: relative;
    width: 100%;
  }
  .filter-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    #remove-filter-logo {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
  }
  @media screen and (max-width: 375px) {
    .filter-container {
      top: -0.7rem;
    }
  }
  @media screen and (min-width: 480px) {
    .filter-container #remove-filter-logo {
      width: 35px;
      height: 35px;
    }
  }
}
