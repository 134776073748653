.Footer {
    height: 6rem;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // bottom: 0;
    // width: 100%;
    margin-top:auto; 

    
    small {
        text-align: center;
        margin-top: 2rem;
    }
    .attribution:first-of-type {
        padding-bottom: 0rem;

    }
    .attribution {
        color: rgba(255, 255, 255, 0.8);


        font-size: 1rem;
        text-align: center;
        
        a {
            color: #4cdbe6;
        }
    }
   
}