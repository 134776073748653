.GameDate {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background-color: #F0F0F0;
    margin: 0 1rem;
    padding: 0 0.5rem;
    line-height: 3rem;
    border-top: solid 2rem #F0F0F0;
    @media screen and (min-width: 1280px) {
      
        margin-left: 1.5rem;
    }

}
