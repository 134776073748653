.Filter {
    // width: 100vw;
    // *:focus {
    //     outline: none;
    // }
    // input:focus, textarea:focus, select:focus{
    //     outline: none;
    // }
  
        select {
            border: none;
            background-color: white;
            background: url("/images/drop-down-arrow.svg") no-repeat 90% 55%;
            background-size: 1rem;
            -o-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 2.2rem;
            padding-left: 0.8rem;
            
        }

    word-wrap: break-word;
    line-height: 1rem;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    width: 10em;
    min-height: 1rem;
    background: #fff;
    display: inline-block;
    padding: .5rem 0 .5rem 0.5em;
    color: rgba(0,0,0,.87);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    -webkit-transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease,-webkit-box-shadow .1s ease;

    background-color: white;
   
    &.filtered {
        width: 13.5rem;
    }




    .drop-down-arrow {
        width: 1rem;
    }
    #filter-logo {
        position: absolute;
        right: 0;
        top: -15px;
        width: 50px;
    }
    #remove-filter-logo {
        position: absolute;
        right: 15px;
        top: -5px;
        width: 30px;
    }
    .filter-select {
        // position: absolute;
        right: 40px;
        width: 100%;
        color: #000;
        color: rgba(0,0,0,0);
        text-shadow: 0 0 0 #000;
        outline: none;

        &.disabled {
            color: rgba(191,191,191,.87);
        }
      

    }

    select option:disabled {
        color: #000;
        font-weight: bold;
    }
    @media screen and (max-width: 375px) {
        padding: .2rem 0 .2rem 0.5em;
     }
    
    
    // position: sticky;
    // position:fixed;
    // right: 0rem;
    // top: 26rem;
}
