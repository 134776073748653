$japan-pink: #E09DA6;
$japan-green: #4F817F;
$japan-yellow: #F2C59E;
$japan-brown: #6E4E53;
$japan-orange: #EB6E52;
$japan-purple: #552151;
$japan-off-white: #FFE9CB;

.Header {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // border-top:  6px solid $japan-pink;
  width: 100vw;
  position: fixed;
  top: 0;
  margin-top: 0;
  background-color: #3C2D84;
  a {
    text-decoration: none;


  }
  

  .locale-container {
    color: white;
    margin-left: 1.5rem;
    font-size: 1.4rem;
    display: flex;
    .locale-icon {
      color: white;
    }
  }
  h1 {
    // color: white;
    color: white;
    font-size: 1.6rem;
    font-weight: normal;
    text-align: center;
    font-family: 'archive';

  }

  #logo {
    height: 50px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .profile-icon {
    color: white;
    height: 5rem;
    margin-right: 2rem;
  }
 
}
