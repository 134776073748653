.Tab {
    display: flex;
    flex-direction: row;
    border-bottom: 1rem #F0F0F0 solid;
    background-color: #3C2D84;

    
    .Tab-link {
        text-decoration: none;
        color: black;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
        width: 100%;
        color: rgba(255, 255, 255, .5);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .active {
        color: white;
    }

    .Tab-link {
        border-right: 1px solid rgba(255, 255, 255, .5);
    };

    .Tab-link:last-child {
        border-right: none;
    }

}
